/* unplugin-vue-components disabled */var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-page"},[_c('img',{staticClass:"login-page__background",attrs:{"src":require("@/assets/images/login_bg.svg")}}),_c('div',{staticClass:"login-page__header"},[_c('Logo')],1),_c('div',{staticClass:"login-page-container",class:[_vm.formClass, { wide: false }]},[_c('div',{staticClass:"login-page-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"login-page-card"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column column-form",style:({
                        gridColumn: _vm.action === 'register' ? '1/3' : '1/3',
                    })},[_c('AuthForm',{attrs:{"action":_vm.action}})],1)])]),(_vm.action == 'register')?_c('div',{staticClass:"disclaimer login-page__bottom-text"},[_vm._v(" Регистрируясь, вы соглашаетесь с "),_c('router-link',{attrs:{"to":"/privacy","target":"_blank","active-class":""}},[_vm._v(" политикой обработки ")]),_c('router-link',{attrs:{"to":"/privacy","target":"_blank","active-class":""}},[_vm._v(" персональных данных ")]),_vm._v(" и "),_c('router-link',{attrs:{"to":"/agreement","target":"_blank","active-class":""}},[_vm._v(" договором оферты ")])],1):_vm._e(),(_vm.action == 'login')?_c('div',{staticClass:"disclaimer login-page__bottom-text"},[_vm._v(" Нет аккаунта? "),_c('router-link',{attrs:{"to":{
                    name: 'Register',
                    query: _vm.$route.query.backUrl
                        ? { backUrl: _vm.$route.query.backUrl }
                        : '',
                },"active-class":""}},[_vm._v(" Зарегистрируйтесь ")])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }