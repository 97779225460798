//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AuthForm from "@/components/Auth/Form";
import Logo from "@/views/public/common/P/Logo.vue";
export default {
  meta() {
    const name = this.title;
    const meta_description = this.meta_description; //!&:
    return {
      title: name ? `${name}` : undefined,
      ////&: title: name ? `SalesFinder - ${ name }` : undefined,
      meta: [{
        name: "description",
        content: `${meta_description}`
      }, {
        name: "viewport",
        content: "width=device-width, user-scalable=no, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0"
      }]
    };
  },
  data() {
    return {
      yourValue: ""
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    title() {
      if (this.action == "login") return "Вход для клиентов"; //@V: 'Вход'
      if (this.action == "register") return "Регистрация"; //@V: 'Регистрация'
      if (this.action == "restorepassword") return "Восстановление пароля"; //@V:'Восстановление пароля'
      return "";
    },
    //!&:
    meta_description() {
      if (this.action == "login") return "Вход для клиентов сервиса SalesFinder";
      if (this.action == "register") return "Зарегистрируйтесь бесплатно в сервисе SalesFinder и получите доступ ко всем аналитическим инструментам сервиса";
      if (this.action == "restorepassword") return "Забыли пароль от аккаунта SalesFinder? Заполните форму восстановления";
      return "";
    },
    formClass() {
      if (this.action == "login" && this.$mq !== "mobile") return "mt100";
      if (this.action == "register" && this.$mq !== "mobile") return "mt60";
      if (this.action == "restorepassword" && this.$mq !== "mobile") return "mt120";
      return "";
    }
  },
  components: {
    AuthForm,
    Logo
  }
};